/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

let nextRoute = ``

exports.onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname
}

//code to handle loading-chunk failed error
window.addEventListener('unhandledrejection', event => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute
    }
  }
})

// code to refresh the website when a new build is deployed / service worker updated
exports.onServiceWorkerUpdateReady = () => window.location.reload()
